<template>
    <v-container style="background:#f2f2f2; min-height:100vh;">
        <v-row class="py-0" style="background:white;">
            <v-col cols="3">
                <v-btn @click="atras" text style="text-transform: capitalize;">
                    <v-icon> mdi-chevron-left</v-icon> Atrás
                </v-btn>
            </v-col>
            <v-col cols="6" class="py-4" style="text-align:center; font-size:18px;">
                Tu Plato
            </v-col>
        </v-row>
        <template v-for="(plato,index) in platos">
            <v-card flat :key="index">
                <div style="text-align:left; background-color:#f2f2f2!important;">
                    <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                        <v-col class="my-1" cols="4">
                            <v-img style="height:70px!important;" :src="foto(plato.producto)"></v-img>
                        </v-col>
                        <v-col cols="8" class="py-2">
                            {{producto(plato.producto)}}
                            <br><strong>{{plato.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                            <div style="float: right; transform: scale(.9);" class="pt-2">
                                <a style="color:#011e7a; background:#e8e8e8; padding:5px 10px; border-radius:5px;">  <strong style="cursor:pointer;" class="px-3" @click="menos(plato, index)">- </strong> {{plato.cantidad}} <strong class="px-3" style="cursor:pointer;" @click="mas(plato, index)"> + </strong> </a> 
                                <v-btn style="margin-top:-4px;" small icon @click="removeItem(index)"><v-icon>   mdi-delete </v-icon></v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </template>


        <div v-if="sucursal.status=='activo' && platos.length>0">
            <v-divider class="mx-4"></v-divider>
            <v-card-title>Comentarios</v-card-title>
            <v-card-text>
                <v-textarea solo name="input-7-4" v-model="comentario" label="Añade instrucciones o comentarios"></v-textarea>
                En este campo no se aceptan modificaciones que generen valor adicional a la factura. Puedes llamar a sucursal para realizar cambios.
            </v-card-text>
        </div>
        
        <div style="filter:opacity(0.21);" v-if="platos.length==0 && sucursal.status=='activo'">
            <v-img max-width="50vw" style="margin:25vw 25vw 30px 25vw;" src="https://tacoselguero.mx/wp-content/uploads/2021/05/esquema-de-plato-y-cubiertos.png"></v-img>
            <strong style="display: table; font-size: 25px; margin: auto;">Plato Vacio</strong>
        </div>

        <!-- cerrado -->
        <div style="filter:opacity(0.21);" v-if="sucursal.status=='inactivo'">
            <v-img max-width="50vw" style="margin:25vw 25vw 30px 25vw;" src="https://tacoselguero.mx/wp-content/uploads/2021/05/close.png"></v-img>
            <strong style="display: table; font-size: 25px; margin: auto;">La sucursal ya cerró</strong>
        </div>
        
        <v-card v-if="platos.length>0 && sucursal.status=='activo'" class="perro" @click="addToCart()" style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a;">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="5">
                    <a style="color:white; padding:5px 10px; border-radius:5px;">  Agregar Plato </a>
                </v-col>
                <v-col style="cursor:pointer;" cols="7">
                    Subtotal: {{total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </v-card>


        
         <v-card v-else class="perro" style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a75;">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="5">
                    <a style="color:white; padding:5px 10px; border-radius:5px;">  Agregar Plato </a>
                </v-col>
                <v-col style="cursor:pointer;" cols="7">
                    Subtotal: {{total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data:()=>({  
        comentario:'',
        index:0,
    }),
    methods:{
        addToCart() {
            const orden={producto:100, cantidad:1, total:'', comentario:'', atributos:''}

            orden.total = this.total
            orden.comentario = this.comentario
            orden.atributos = this.platos

            this.$store.dispatch("carrito/addItem", orden).then(response => {
                this.comentario=''
                this.$store.dispatch('plato/resetItem');
                this.index = this.index+1
                this.atras()
            });
            
        },
        atras(){
            window.history.back();
        },
        foto(id){
            return this.$store.state.producto.productos.filter(producto=>producto.id == id).map(producto => producto.foto)[0]
        },
        producto(id){
            return this.$store.state.producto.productos.filter(producto=>producto.id == id).map(producto => producto.nombre)[0]
        },
        removeItem(index) {
            this.$store.dispatch('plato/removeItem', index);
        },
        menos(plato, index){
            if(plato.cantidad>=2){
                plato.total = plato.total/plato.cantidad
                plato.cantidad = plato.cantidad - 1
                plato.total = plato.total*plato.cantidad
                this.$store.dispatch('plato/editItem', plato, index);
            }else{
                this.removeItem(index)
            }
        },
        mas(plato, index){
            plato.total = plato.total/plato.cantidad
            plato.cantidad = plato.cantidad + 1
            plato.total = plato.total*plato.cantidad
            this.$store.dispatch('plato/editItem', plato, index);
        }
    },
    created(){
        this.$store.dispatch('producto/getProductos') 
        this.$store.dispatch('sucursal/getSucursales')
    },
    computed:{
        platos() {
            return this.$store.state.plato.platos
        },
        total(){
            var perro = this.$store.state.plato.platos
            if(perro!=undefined){
                var sum = 0
                for(var i=0; i<perro.length; i++){
                        sum = sum + perro[i].total
                }
                return sum
            }else{
                return 0
            }
        },
        sucursal(){
            return this.$store.state.sucursal.sucursales.filter(sucursal=>sucursal.id == localStorage.getItem("sucursal"))[0]
        }
    },
    mounted(){
      Echo.channel('test')
          .listen('Test', (e) => {
            this.$store.dispatch('sucursal/getSucursales')
          })
    },
}
</script>

<style>

</style>